import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const ContactsPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO
        title="All posts"
        keywords={[`blog`, `gatsby`, `javascript`, `react`]}
      />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <h3 id="unordered">
            <strong>
              <strong>Prices</strong>
            </strong>
          </h3>
          <h4 id="unordered">
            <strong>
              <strong>Concerts & Events</strong>
            </strong>
          </h4>

          <p>
            <blockquote>
              Capture the heartbeat of your event in every frame.
            </blockquote>
          </p>

          <p>
            Whether it's the thrill of live music or the highlights of a
            corporate event, I’ll capture every memorable moment with dynamic,
            documentary-style shots. I blend into the crowd, staying alert for
            raw, authentic moments and electrifying details that tell the full
            story of the experience. Perfect for concerts, company events,
            private celebrations, and more—I’m here to document every key
            moment, from candid interactions to the main attractions.
          </p>
          <p>Choose from:</p>

          <ul>
            <li>
              {" "}
              <strong>
                <strong>Basic Coverage</strong>
              </strong>
              : 2 hours of shooting. I’ll deliver 10 photos in just 24 hours, so
              you can share the best moments instantly, with the remaining 30
              images ready in 3 days.
            </li>
            <li>
              {" "}
              <strong>
                <strong>Extended Coverage</strong>
              </strong>
              : 4 hours of shooting. Get 10 photos in 24 hours and a final
              selection of 80 images in 3 days. Perfect for events that need
              extensive coverage.
            </li>
          </ul>

          <table>
            <thead>
              <tr>
                <th>Package</th>
                <th>Shooting Time</th>
                <th>Price</th>
                <th>Photos Delivered</th>
                <th>Delivery Time</th>
                <th>Final Image Count</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Basic Coverage</td>
                <td>2 hours</td>
                <td>EUR 160</td>
                <td>10 photos within 24 hours</td>
                <td>full album in 3 days</td>
                <td>40</td>
              </tr>
              <tr>
                <td>Extended Coverage</td>
                <td>4 hours</td>
                <td>EUR 320</td>
                <td>10 photos within 24 hours</td>
                <td>full album in 3 days</td>
                <td>80</td>
              </tr>
            </tbody>
          </table>

          <p>
            {" "}
            Both packages include basic post-processing to perfect your photos
            with natural, yet impactful enhancements.
          </p>
          <p>
            {" "}
            Within 24 hours, you'll receive your first 10 photos in full and web
            quality, along with bonus formats sized for Instagram posts and
            stories.
          </p>

          <h4 id="unordered">
            <strong>
              <strong>Lens on the Band</strong>
            </strong>
          </h4>
          <p>
            <blockquote>Backstage access to your best moments.</blockquote>
          </p>
          <p>
            Take fans behind the scenes with a full day of insider shots that
            capture the essence of your band. I’ll document your pre-show
            rituals, moments in the backstage, soundcheck and the show itself.
            Reveal your band’s energy and personality through authentic photos.{" "}
          </p>

          <table>
            <thead>
              <tr>
                <th>Shooting Time</th>
                <th>Price</th>
                <th>Photos Delivered</th>
                <th>Delivery Time</th>
                <th>Final Image Count</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>4 hours</td>
                <td>EUR 320</td>
                <td>10 photos within 24 hours</td>
                <td>full album in 7 days</td>
                <td>70</td>
              </tr>
            </tbody>
          </table>

          <p>
            {" "}
            Within 24 hours, you'll receive your first 10 photos in full and web
            quality, along with bonus formats sized for Instagram posts and
            stories.
          </p>

          <h4 id="unordered">
            <strong>
              <strong>Promo Shoot for Bands</strong>
            </strong>
          </h4>
          <p>
            <blockquote>
              Photos that scream you – perfect for promotions.
            </blockquote>
          </p>
          <p>
            Craft a look that represents your band’s vibe with a portrait
            session tailored to bring out your best. This shoot is designed for
            promotional magic, with careful attention to post-processing to make
            each image polished and professional.{" "}
          </p>

          <table>
            <thead>
              <tr>
                <th>Shooting Time</th>
                <th>Price</th>
                <th>Location</th>
                <th>Final Image Count</th>
                <th>Delivery Time</th>
                <th>Post-Processing</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>60–90 minutes</td>
                <td>EUR 200</td>
                <td>outdoor or studio (additional fee)</td>
                <td>15</td>
                <td>14 days</td>
                <td>detailed retouching</td>
              </tr>
            </tbody>
          </table>

          <h4 id="unordered">
            <strong>
              <strong>Portrait Session</strong>
            </strong>
          </h4>
          <p>
            <blockquote>
              Authentic portraits designed to make an impression.
            </blockquote>
          </p>
          <p>
            From professional headshots to personal and business portraits, this
            session is about capturing you at your best. Whether it’s outdoors
            or in a studio (additional fee for a studio), we’ll find the right
            setting and tone to reflect your unique style and personality.
          </p>

          <table>
            <thead>
              <tr>
                <th>Shooting Time</th>
                <th>Price</th>
                <th>Location</th>
                <th>Final Image Count</th>
                <th>Delivery Time</th>
                <th>Post-Processing</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>60 minutes</td>
                <td>EUR 180</td>
                <td>outdoor or studio (additional fee)</td>
                <td>15</td>
                <td>14 days</td>
                <td>detailed retouching</td>
              </tr>
            </tbody>
          </table>

          <h4 id="unordered">
            <strong>
              <strong>Family Portrait Session</strong>
            </strong>
          </h4>
          <p>
            <blockquote>
              Natural, timeless memories of you and your loved ones.
            </blockquote>
          </p>
          <p>
            No stiff poses or forced smiles here. This session is all about
            capturing the beautiful chaos, laughter, and warmth of your family’s
            everyday moments. Whether it’s a quiet Sunday at home or a big
            family celebration, I’ll be there to catch every hug, laugh, and
            cake-smeared grin.
          </p>
          <p>What your family session might look like:</p>

          <ul>
            <li>
              {" "}
              <strong>
                <strong>Family Snapshot:</strong>
              </strong>{" "}
              60 minutes of shooting, with 35 images delivered in 14 days.
              Includes basic post-processing and light retouching.
            </li>
            <li>
              {" "}
              <strong>
                <strong>Family Documentary:</strong>
              </strong>{" "}
              3 hours of immersive shooting, yielding 70 candid images delivered
              in 14 days. A wonderful way to tell your family’s story,
              preserving the moments that matter most.
            </li>
          </ul>

          <p>Moments we can capture together:</p>

          <ul>
            <li>
              Celebrating a birthday, baptism, anniversary, or any milestone
            </li>
            <li>
              Baking cookies in the kitchen (with a little flour fight,
              perhaps?) or cooking a family-favorite recipe together
            </li>

            <li>
              Decorating the Christmas tree, carving pumpkins, or lighting up
              sparklers on New Year’s Eve
            </li>

            <li>
              Running through fields with kites or riding bikes down favorite
              trails
            </li>

            <li>
              Spending the evening watching a movie or enjoying a family board
              game battle
            </li>

            <li>Building blanket forts or having a living room dance party</li>

            <li>
              Visiting grandparents for a cozy afternoon of tea, stories, and
              maybe some family history
            </li>

            <li>
              Heading to a local farm for apple picking, a petting zoo adventure
            </li>

            <li>
              Gardening together, playing in a leaf pile, or a family picnic in
              a favorite spot
            </li>
          </ul>

          <p>
            Whatever feels like{" "}
            <em>
              <em>home</em>
            </em>{" "}
            to you – that’s where the magic happens. Let’s make it timeless.
          </p>

          <table>
            <thead>
              <tr>
                <th>Package</th>
                <th>Shooting Time</th>
                <th>Price</th>
                <th>Delivery Time</th>
                <th>Final Image Count</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Family Snapshot</td>
                <td>60 minutes</td>
                <td>EUR 140</td>
                <td>14 days</td>
                <td>35</td>
              </tr>
              <tr>
                <td>Family Documentary</td>
                <td>3 hours</td>
                <td>EUR 280</td>
                <td>14 days</td>
                <td>70</td>
              </tr>
            </tbody>
          </table>

          <p>
            {" "}
            This is more than just a photo session; it’s a way to relive your
            favorite family moments – vivid, joyful, and beautifully real.
          </p>

          <h4 id="unordered">
            <strong>
              <strong>A Few Extra Perks</strong>
            </strong>
          </h4>
          <p>
            Travel costs? On the house if you’re in Moravskoslezský kraj. For
            shoots in Olomoucký kraj, Zlínský kraj, Prague or Brno, it’s a CZK
            500 transport fee. Outside of these areas? Reach out! Let’s arrange
            a travel option and cost that works for your location. If
            applicable, accommodation costs are covered by the client.
          </p>

          <table>
            <thead>
              <tr>
                <th>Region</th>
                <th>Transport Fee</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Moravskoslezský kraj</td>
                <td>free</td>
              </tr>
              <tr>
                <td>Olomoucký kraj</td>
                <td>EUR 20</td>
              </tr>
              <tr>
                <td>Zlínský kraj</td>
                <td>EUR 20</td>
              </tr>
              <tr>
                <td>Praha</td>
                <td>EUR 20</td>
              </tr>
              <tr>
                <td>Brno</td>
                <td>EUR 20</td>
              </tr>
            </tbody>
          </table>

          <p>
            For your peace of mind, I offer secure cloud storage for your photos
            for up to one year. If anything happens to your device or you
            accidentally delete your files, don’t worry – let me know, and I’ll
            resend them. Just before the one-year period ends, you’ll receive a
            reminder from me to ensure you have everything you need.
          </p>

          <p>
            Not seeing exactly what you’re looking for in these packages? Let’s
            create one that’s a perfect fit! I’m here to customize and make it
            work for your vision.
          </p>

          <h3 id="unordered">
            <strong>
              <strong>Ceník</strong>
            </strong>
          </h3>
          <h4 id="unordered">
            <strong>
              <strong>Koncerty a události</strong>
            </strong>
          </h4>
          <p>
            <blockquote>Ready, steady, jedem!</blockquote>
          </p>
          <p>
            Ať už jde o energii koncertů nebo atmosféru firemních akcí, jsem s
            Vámi! Jako nenápadný pozorovatel zmapuju všechno podstatné - od
            spontánních momentů v publiku přes backstage až po hlavní dění na
            pódiu. Zachytím pro Vás ty nejlepší okamžiky Vaší akce. Ať už je to
            koncert, firemka nebo soukromá párty, postarám se, aby Vám fotky
            připomněly tu pravou atmosféru.
          </p>
          <p>Vyberte si balíček:</p>
          <ul>
            <li>
              {" "}
              <strong>
                <strong>Základní reportáž</strong>
              </strong>
              {""}: 2 hodiny focení. Dostanete 10 fotek do 24 hodin, abyste
              mohli bezprostředně sdílet nejdůležitější momenty akce,
              zbývajících 30 fotek obdržíte do 3 dnů. Ideální pro menší akce a
              rychlý výběr top momentů.
            </li>
            <li>
              {" "}
              <strong>
                <strong>Obsáhlá reportáž</strong>
              </strong>
              {""}: 4 hodiny focení. Dostanete 10 fotek do 24 hodin, celkem 80
              fotek do 3 dnů. Vhodné pro akce, ze kterých chcete mít opravdu
              podrobnou fotoreportáž.
            </li>
          </ul>

          <table>
            <thead>
              <tr>
                <th>Balíček</th>
                <th>Délka focení</th>
                <th>Cena</th>
                <th>Doručení highlightů</th>
                <th>Rychlost zpracování</th>
                <th>Finální počet fotek</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Základní reportáž</td>
                <td>2 hodiny</td>
                <td>4,000 Kč</td>
                <td>10 fotek do 24 hodin</td>
                <td>celé album do 3 dnů</td>
                <td>40</td>
              </tr>
              <tr>
                <td>Obsáhlá reportáž</td>
                <td>4 hodiny</td>
                <td>8,000 Kč</td>
                <td>10 fotek do 24 hodin</td>
                <td>celé album do 3 dnů</td>
                <td>80</td>
              </tr>
            </tbody>
          </table>
          <p>
            {" "}
            Součástí každého balíčku je základní postprodukce, aby fotografie
            měly přirozený, ale zároveň působivý vzhled.
          </p>

          <p>
            {" "}
            Do 24 hodin obdržíte prvních 10 fotografií v plné a webové kvalitě,
            společně s bonusovými formáty pro Instagram stories a příspěvky.
          </p>

          <h4 id="unordered">
            <strong>
              <strong>Lens on the Band</strong>
            </strong>
          </h4>
          <p>
            <blockquote>Backstage přístup k nejlepším momentům!</blockquote>
          </p>
          <p>
            Odhalte fanouškům zákulisí – vše od příprav, momentů v backstagi,
            přes zvukovou zkoušku, až po samotnou show. Ukažte fanouškům Vaši
            kapelu takovou, jaká opravdu je - díky autentickým backstage
            snímkům.
          </p>

          <table>
            <thead>
              <tr>
                <th>Délka focení</th>
                <th>Cena</th>
                <th>Doručení highlightů</th>
                <th>Rychlost zpracování</th>
                <th>Finální počet fotek</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>4 hodiny</td>
                <td>8,000 Kč</td>
                <td>10 fotek do 24 hodin</td>
                <td>celé album do 7 dnů</td>
                <td>70</td>
              </tr>
            </tbody>
          </table>

          <p>
            {" "}
            Do 24 hodin obdržíte prvních 10 fotografií v plné a webové kvalitě,
            společně s bonusovými formáty pro Instagram stories a příspěvky.
          </p>

          <h4 id="unordered">
            <strong>
              <strong>Promo focení pro kapely</strong>
            </strong>
          </h4>
          <p>
            <blockquote>Vizuál, který nechá Vaši hudbu vyniknout.</blockquote>
          </p>
          <p>
            Společně vytvoříme look, který přesně vystihne DNA Vaší kapely.
            Tohle focení je navrženo přímo pro Váš promo arzenál - každý záběr
            bude profesionálně zpracovaný s důrazem na ty nejmenší detaily.{" "}
          </p>

          <table>
            <thead>
              <tr>
                <th>Délka focení</th>
                <th>Cena</th>
                <th>Lokace</th>
                <th>Finální počet fotek</th>
                <th>Rychlost zpracování</th>
                <th>Postprocessing</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>60–90 minut</td>
                <td>5,000 Kč</td>
                <td>exteriér / studio (za příplatek)</td>
                <td>15</td>
                <td>14 dní</td>
                <td>detailní retuš</td>
              </tr>
            </tbody>
          </table>

          <h4 id="unordered">
            <strong>
              <strong>Portrétní focení</strong>
            </strong>
          </h4>
          <p>
            <blockquote>Autentické portréty, které udělají dojem.</blockquote>
          </p>
          <p>
            Ať už jde o profesionální headshot nebo osobní či business portrét -
            postarám se, aby Vás fotka co nejlépe charakterizovala. Ať už venku,
            nebo ve studiu (studio za příplatek), společně najdeme lokaci a
            styl, které podtrhnou Vaši osobnost.
          </p>

          <table>
            <thead>
              <tr>
                <th>Délka focení</th>
                <th>Cena</th>
                <th>Lokace</th>
                <th>Finální počet fotek</th>
                <th>Rychlost zpracování</th>
                <th>Postprocessing</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>60 minut</td>
                <td>4,500 Kč</td>
                <td>exteriér / studio (za příplatek)</td>
                <td>15</td>
                <td>14 dní</td>
                <td>detailní retuš</td>
              </tr>
            </tbody>
          </table>

          <h4 id="unordered">
            <strong>
              <strong>Rodinné focení</strong>
            </strong>
          </h4>
          <p>
            <blockquote>
              Přirozené momenty, nepřikrášlené emoce, pravé vzpomínky.
            </blockquote>
          </p>
          <p>
            Žádné strnulé pózy nebo nucené úsměvy. Tohle focení je o zachycení
            krásy chaosu, smíchu a hřejivé atmosféry Vašich každodenních chvil.
            Ať už jde o klidnou neděli doma, nebo velkou rodinnou oslavu, budu
            tam, abych zachytila každé objetí, úsměv i tváře umazané od dortu.{" "}
          </p>
          <p>Jak Vaše rodinné focení může vypadat:</p>
          <ul>
            <li>
              {" "}
              <strong>
                <strong>Rodinný moment</strong>
              </strong>
              {""}: 60 minut focení, 35 upravených fotografií, dodání do 14 dnů.
              Zahrnuje základní úpravy a lehké retuše.
            </li>
            <li>
              {" "}
              <strong>
                <strong>Rodinný dokument</strong>
              </strong>
              {""}: 3 hodiny společného času, během kterých zachytíme Vaši
              rodinu tak, jak ji máte nejradši, 70 snímků, dodání do 14 dnů.
            </li>
          </ul>

          <p>Co spolu můžeme zachytit:</p>

          <ul>
            <li>
              oslavu narozenin, křtiny, výročí, nebo jakýkoliv důležitý milník
            </li>
            <li>
              pečení cukroví, nebo příprava Vašeho oblíbeného rodinného receptu
            </li>

            <li>
              zdobení vánočního stromečku, vydlabávání dýní, nebo zapalování
              prskavek na Silvestra
            </li>

            <li>běhání po louce s draky, nebo rodinná vyjížďka na kolech</li>

            <li>rodinný filmový večer, nebo bitva v deskovkách</li>

            <li>stavění bunkrů z dek, nebo diskotéka v obýváku</li>

            <li>
              odpolední čaj u prarodičů a vzpomínání na zajímavé rodinné
              historky
            </li>

            <li>sbírání jablek na místní farmě, nebo výlet do zoo</li>

            <li>
              zahradničení, skákání do podzimního listí, nebo piknik na
              oblíbeném místě
            </li>
          </ul>

          <p>Zkrátka cokoliv, co pro Vás znamená kouzlo domova.</p>

          <table>
            <thead>
              <tr>
                <th>Balíček</th>
                <th>Délka focení</th>
                <th>Cena</th>
                <th>Rychlost zpracování</th>
                <th>Finalní počet fotek</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Rodinný moment</td>
                <td>60 minut</td>
                <td>3,500 Kč</td>
                <td>14 dní</td>
                <td>35</td>
              </tr>
              <tr>
                <td>Rodinný dokument</td>
                <td>3 hodiny</td>
                <td>7,000 Kč</td>
                <td>14 dní</td>
                <td>70</td>
              </tr>
            </tbody>
          </table>

          <p>
            {" "}
            Ty nejobyčejnější chvíle jsou často ty nejvzácnější. Když si všichni
            čtete před spaním, když děti krmí slepice u babičky, nebo když spolu
            prostě blbnete na zahradě. Tyhle momenty si zaslouží být zvěčněné.
          </p>
          <h4 id="unordered">
            <strong>
              <strong>Doprava</strong>
            </strong>
          </h4>

          <p>
            Pokud jste z Moravskoslezského kraje, cestovné je zdarma. Pro focení
            v Olomouckém kraji, Zlínském kraji, Praze a Brně je poplatek za
            dopravu 500 Kč. Chtěli byste fotit jinde? Ozvěte se mi a domluvíme
            se na možnostech. Případné náklady na ubytování jsou hrazeny
            klientem.
          </p>

          <table>
            <thead>
              <tr>
                <th>Oblast</th>
                <th>Cestovné</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Moravskoslezský kraj</td>
                <td>zdarma</td>
              </tr>
              <tr>
                <td>Olomoucký kraj</td>
                <td>500 Kč</td>
              </tr>
              <tr>
                <td>Zlínský kraj</td>
                <td>500 Kč</td>
              </tr>
              <tr>
                <td>Praha</td>
                <td>500 Kč</td>
              </tr>
              <tr>
                <td>Brno</td>
                <td>500 Kč</td>
              </tr>
            </tbody>
          </table>

          <h4 id="unordered">
            <strong>
              <strong>Pár bonusů navíc</strong>
            </strong>
          </h4>
          <p>
            Vaše fotky budou bezpečně uchovány na cloudovém úložišti po dobu
            jednoho roku. Pokud se něco stane s Vaším zařízením nebo omylem
            smažete soubory, nemusíte se obávat – stačí mi dát vědět a já Vám je
            znovu pošlu. Těsně před uplynutím roční lhůty se připomenu, abyste
            se mohli ujistit, že všechny fotky máte v pořádku u sebe.
          </p>

          <p>
            Nenašli jste v nabídce to, co hledáte? Nevadí! Kontaktujte mě a
            připravíme balíček na míru, který přesně odpovídá Vašim představám.
          </p>
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    smallPic: file(relativePath: { eq: "aneta_jursova_fashion_03.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    medPic: file(relativePath: { eq: "aneta_jursova_fashion_03.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    largePic: file(relativePath: { eq: "aneta_jursova_fashion_03.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <ContactsPage location={props.location} data={data} {...props} />
    )}
  />
)
